import React, { useCallback, useMemo } from "react";
import {StyleSheet, View} from "react-native";
import {Button, Colours, TableRow, Text} from "react/parkable-components";
import Strings from "react/constants/localization/localization";
import {Routes} from "react/navigation/root/root.paths";
import {createRoute, useNavigation} from "react/navigation/constants";
import ParkableBaseView from "react/components/common/ParkableBaseView";
import {useEvTokensForUsers} from "react/api/ev";
import {useFocusEffect} from '@react-navigation/native';
import {useRfidTokens} from "../../../../api/rfid";
import {useCurrentUser} from "../../../../api/user/user.api";
import {RfidType} from "../../../../model/rfid/RfidType";
import {DisplayToken} from "./DisplayToken";
import Icons from "../../../../parkable-components/icon/Icons";
import Icon from "../../../../parkable-components/icon/Icon";

export const RfidCardsView = () => {
    const navigation = useNavigation();
    const { user } = useCurrentUser();

    const { tokens: evTokens, isLoading, mutate: mutateEvTokens} = useEvTokensForUsers();
    const {data: rfidTokens, mutate: mutateRfidTokens} = useRfidTokens(user?.id);
    const tokenDisplayDetails = useMemo(() => {
        const displayTokens: Array<DisplayToken> = [];
        evTokens?.forEach( (t) => {
            displayTokens.push({id: t.uid, type: RfidType.Ev});
        });
        rfidTokens?.forEach( (t) => {
            displayTokens.push({id: t.id, type: RfidType.Parking});
        });
        displayTokens.sort();
        return displayTokens;
    }, [evTokens, rfidTokens]);

    const AddNewCardButton = () => (
        <View style={styles.addNewButton}>
            <Button border center textProps={{ h5: true }} style={styles.button} onPress={onAddRfidCardPress}>
                {Strings.add_new_rfid}
            </Button>
        </View>
    );

    const mutate = useCallback( () => {
        void mutateRfidTokens();
        void mutateEvTokens();
    }, [mutateRfidTokens, mutateEvTokens]);

    const onAddRfidCardPress = useCallback(() => {
        navigation.push(Routes.AddEditRfidCardView, {existingToken: undefined});
    }, [navigation, mutate]);

    const onEditRfidCardPress = useCallback((rfidNumber: string, type: RfidType) => {
        const tokenDetails = {
            id: rfidNumber,
            type,
        };

        navigation.push(Routes.AddEditRfidCardView, {existingToken: tokenDetails});
    }, [navigation, mutate]);

    useFocusEffect( useCallback(() => {
        mutate();
    }, [mutate]) );

    const Card = (props: { card: DisplayToken }) => (

            <TableRow
                chevron
                iconLeft={props.card.type === RfidType.Ev ? Icons.electricvehicleplug : Icons.carandbarrier}
                onPress={() => {
                    onEditRfidCardPress(props.card.id, props.card.type);
                }}
            >
                {props.card.id}
            </TableRow>

    );

    return (
        <ParkableBaseView scrollable={false} loading={isLoading}>
            <View style={styles.content}>
                <View style={{ marginTop: 20, paddingTop: 20 }}>
                    <Text h2 bold>
                        {Strings.rfid_cards}
                    </Text>
                </View>
                <View>
                    <Text p grey>
                        {Strings.add_new_and_manage_existing_rfids}
                    </Text>
                </View>
                <View style={styles.cardsContainer}>
                    <View style={{ flex: 1 }}>
                        {tokenDisplayDetails ? tokenDisplayDetails.map((card, i) => <Card card={card} key={i} />) : ""}
                    </View>
                </View>
                <AddNewCardButton />
            </View>
        </ParkableBaseView>
    );
};

export const RfidCardsRoute = createRoute({
    path: Routes.RfidCardsView,
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
    },
    cardsContainer: {
        flex: 1,
        marginTop: 16,
    },
    rfidCardName: {
        fontSize: 12,
    },
    rowContainer: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image: {
        alignSelf: "center",
        height: 20,
        width: 20,
        marginRight: 10,
    },
    cardRow: {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_10,
        paddingVertical: 4,
        marginVertical: 4,
    },
    cardIdText: {
        fontSize: 16,
    },
    addNewButton: {
        flexDirection: "row",
        paddingTop: 27,
    },
    button: {
        width: "100%",
        height: 54,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 5,
        borderWidth: 1,
        paddingVertical: 10,
        marginVertical: 5,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
    },
});
