import {StyleSheet, View} from "react-native";
import Button from "react/parkable-components/button/Button";
import React, {useCallback, useRef, useState} from "react";
import {Colours, DialogRef, Input, Text} from "react/parkable-components";
import Strings from "react/constants/localization/localization";
import {attachEvRfidToUser, detachEvRfidFromUser} from "react/api/ev";
import {theme} from "react/constants/theme";
import ParkableBaseView from "../../../common/ParkableBaseView";
import {createRoute, NavigationProps, useNavigation} from "../../../../navigation/constants";
import {Routes} from "../../../../navigation/root/root.paths";
import Radio from "../../../../parkable-components/radio/Radio";
import {RfidType} from "../../../../model/rfid/RfidType";
import Dialog from "../../../dialog/Dialog";
import {
    createParkingRfid,
    deleteParkingRfid,
} from "../../../../api/rfid";
import {useCurrentUser} from "../../../../api/user/user.api";
import {DisplayToken} from "./DisplayToken";

type AddRfidCardDialogProps = NavigationProps<Routes.AddEditRfidCardView>;


export const AddEditRfidCardView = (props: AddRfidCardDialogProps) => {

    const navigation = useNavigation();
    const existingToken = props.route.params?.existingToken;
    const { user } = useCurrentUser();
    const [rfidNumber, setRfidNumber] = useState<string | undefined>(!!existingToken ? existingToken.id : undefined);
    const [loading, setLoading] = useState(false);
    const [tokenType, setTokenType] = useState<RfidType|undefined>(!!existingToken ? existingToken.type : RfidType.Parking);
    const errorDialogRef = useRef<DialogRef | null>(null);
    const [errorText, setErrorText] = useState("");
    const [isEditing] = useState(!!existingToken);
    const [removingCard, setRemovingCard] = useState(false);
    const changed = isEditing && (tokenType !== existingToken!.type || rfidNumber !== existingToken!.id);

    const validateInput = (input: string) => {
        const HEX_REGEX = /^[a-zA-Z0-9]+$/;
        const SEPARATOR_REGEX = /[-_:]+/gi;
        const sanitizedCardId = input.replace(SEPARATOR_REGEX, "").toUpperCase().trim();
        return !(input.length < 1 || !HEX_REGEX.test(sanitizedCardId));

    };

    const onSubmit = async () => {
        if (!rfidNumber) {
            return;
        }
        setLoading(true);
        try {
            switch(tokenType) {
                case RfidType.Ev:
                    if(isEditing) {
                        if (existingToken!.type !== tokenType) {
                            //attach the EV RFID token to the user
                            await attachEvRfidToUser(rfidNumber);
                            //delete the parking RFID
                            await deleteParkingRfid(existingToken!.id);
                        } else if(existingToken!.id !== rfidNumber){
                            //attach the EV RFID token to the user
                            await attachEvRfidToUser(rfidNumber);
                            //remove the old EV RFID token rom the user
                            await detachEvRfidFromUser(existingToken!.id);
                        }
                    }
                    else {
                        await attachEvRfidToUser(rfidNumber);
                    }
                    break;
                case RfidType.Parking:
                    if(isEditing) {
                        if (existingToken!.type !== tokenType) {
                            //create a new parking RFID token
                            await createParkingRfid(rfidNumber, user!.id);
                            //remove the EV RFID from the user
                            await detachEvRfidFromUser(existingToken!.id);
                        } else if (existingToken!.id !== rfidNumber) {
                            await createParkingRfid(rfidNumber, user!.id);
                            await deleteParkingRfid(existingToken!.id);
                        }
                    }
                    else {
                        await createParkingRfid(rfidNumber, user!.id);
                    }
                    break;
            }
            navigation.pop();
        } catch (err: any) {
            console.log("error: ", err.message);
            setLoading(false);
            setErrorText(err.message || Strings.error_persists);
            errorDialogRef.current?.show();
        }
    };

    const onChangeTypeRadio = useCallback(async (t: {value: RfidType}) => {
        console.log("setValue to ", t.value);
        setTokenType(t.value);
    }, []);

    const onPressRemoveCard = useCallback (async() => {
        if(loading || removingCard){
            return;
        }

        setRemovingCard(true);
        try {
            switch(existingToken!.type) {
                case RfidType.Ev:
                    await detachEvRfidFromUser(existingToken!.id);
                    break;
                case RfidType.Parking:
                    await deleteParkingRfid(existingToken!.id);
                    break;
            }
            navigation.pop();
        } catch (err: any) {
            console.log("error: ", err.message);
            setLoading(false);
            setErrorText(err.message || Strings.error_persists);
            errorDialogRef.current?.show();
        }
        setRemovingCard(false);
    }, [loading, removingCard, changed]);

    return (
        <ParkableBaseView scrollable={false}>
            <View style={styles.content}>
                <View style={{ marginTop: 5, paddingTop: 5 }}>
                    <Text h1 bold>
                        {isEditing ? Strings.edit_rfid: Strings.add_new_rfid}
                    </Text>
                </View>
                <View style={{ marginTop: 10, paddingTop: 10 }}>
                    <Text h3 bold style={{ paddingBottom: 0, marginBottom: 0 }}>
                        {Strings.whats_your_rfid}
                    </Text>
                </View>
                <Input
                    placeholder={Strings.rfid_number}
                    value={rfidNumber}
                    disabled={removingCard || loading}
                    onChangeText={(num: string) => {
                        setRfidNumber(num);
                    }}
                />

                <View style={{ marginTop: 5, paddingTop: 5}}>
                    <Text h3 bold style={{ paddingBottom: 0, marginBottom: 0 }}>
                        {Strings.what_type_of_rfid}
                    </Text>
                </View>
                <Radio  options={[{label: Strings.parking_access, value: RfidType.Parking}, {label: Strings.ev_charging, value: RfidType.Ev}]}
                        onChange={onChangeTypeRadio}
                        disabled={removingCard || loading}
                        value={tokenType} />

            </View>
            <View style={styles.buttonContainer}>
                <Button
                    center
                    textProps={{ h5: true }}
                    disabled={loading || removingCard || (isEditing && !changed)|| !validateInput(rfidNumber ?? "")}
                    loading={loading}
                    style={[ { backgroundColor: Colours.GREEN_300 }]}
                    onPress={onSubmit}
                >
                    {isEditing ? Strings.update : Strings.add}
                </Button>
                {isEditing? <Button
                    style={styles.removeButtonStyle}
                    plain
                    small
                    center
                    textProps={{
                        red: !loading && !removingCard,
                        grey: loading || removingCard,
                        bold: false }} onPress={onPressRemoveCard}>
                    {Strings.remove_card}
                </Button> : undefined}
            </View>
            <Dialog
                ref={errorDialogRef}
                label={Strings.error}
                labelProps={{style: {color: Colours.NEUTRALS_BLACK, textAlign: 'left'}}}
                title={errorText}
                titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                positiveText={Strings.ok}
                onPositivePress={() => errorDialogRef.current?.hide()}
            />
        </ParkableBaseView>
    );
};

export class AddEditRfidCardRouteParams {
    existingToken: DisplayToken | undefined
}

export const AddEditRfidCardRoute = createRoute({
    path: Routes.AddEditRfidCardView,
    params: {type: AddEditRfidCardRouteParams},
});

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: "column",
        backgroundColor: "white",
    },
    content: {
        flex: 1,
    },
    removeButtonStyle: {
        marginTop: 36,
    },
    singleLineContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
    dayOfWeekHeaderContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    label: {
        color: Colours.NEUTRALS_BLACK,
        textAlign: "left",
        marginBottom: 0,
        marginTop: theme.spacing(1),
    },
    title: {
        textAlign: "left",
        marginTop: theme.spacing(1),
        marginBottom: 0,
        paddingBottom: 0,
    },
});
