import { RootStackParamList } from "./root.routes";
import * as React from "react";
import { Routes } from "./root.paths";
import LandingView from "react/components/landing/LandingView";
import ParkDetailView from "react/components/parkDetails/ParkDetailView_new";
import LoginOrSignupView from "react/components/landing/LoginOrSignupView";
import ParkableMapViewRoot from "react/components/map/parkableMapView/ParkableMapView_root";
import ChangeServerView from "react/components/landing/ChangeServerView";
import FindParkView from "react/components/search/FindParkView";
import SupportView from "react/components/support/SupportView";
import CaseDetailsView from "react/components/support/CaseDetailsView";
import ForgotPasswordView from "react/components/landing/ForgotPasswordView";
import ConfirmStartParkingView from "react/components/parkDetails/confirmStartParking/ConfirmStartParkingView";
import ActiveSessionView from "react/components/parkingViews/ActiveSessionView_new";
import AccountView from "react/components/accountView/AccountView";
import PreferredBaysView from "react/components/preferredBays/PreferredBaysView";
import SelectParkView from "react/components/preferredBays/SelectParkView";
import PricingView from "react/components/pricing/PricingView";
import MapSettingsView from "react/components/accountView/userDetails/MapSettingsView";
import MyDetailsView from "react/components/accountView/userDetails/MyDetailsView";
import SessionHistoryView from "react/components/parkingViews/parking-history/SessionHistoryView";
import { BookingsAndRequestsView } from "react/components/parkingViews/bookings-and-requests/bookings-and-requests.view";
import ListSubscriptionView from "react/components/parkingViews/Subscriptions/ListSubscriptionView";
import AddNewVehicleView from "react/components/accountView/userDetails/AddNewVehicleView_new";
import VehiclesView from "react/components/accountView/userDetails/VehiclesView_new";
import CreateAccountView from "react/components/landing/CreateAccountView";
import SamlLoginView from "react/components/landing/SamlLoginView";
import MessagesView from "react/components/messages/MessagesView";
import VouchersView from "react/components/vouchers/VouchersView";
import SessionSummary from "react/components/parkDetails/sessionSummary/SessionSummary";
import NotificationSettingsView from "react/components/accountView/userDetails/NotificationSettingsView";
import { RfidCardsView } from "react/components/accountView/userDetails/rfid-cards-view/RfidCardsView";
import { AddEditRfidCardView } from "react/components/accountView/userDetails/rfid-cards-view/AddEditRfidCardView";
import CreditCards from "react/components/accountView/userDetails/CreditCards";
import ParkAvailabilityView from "react/components/parkDetails/ParkAvailabilityView";
import VoucherDetail from "react/components/common/voucher/VoucherDetail";
import AddVoucherCode from "react/components/common/voucher/AddVoucherCode";
import SessionHistoryDetailView from "react/components/parkingViews/parking-history/SessionHistoryDetailView";
import SelectSubscriptionPlanView from "react/components/subscriptions/SelectSubscriptionPlanView";
import ParkTomorrowView from "react/components/parkRequest/ParkTomorrowView";
import ConfirmStartReservationView from "react/components/parkDetails/confirmStartReservation/ConfirmStartReservationView";
import StartSubscriptionSummaryView from "react/components/subscriptions/StartSubscriptionSummaryView";
import AcceptTsAndCsView from "react/components/landing/AcceptTsAndCsView";
import PrivacySettingsView from "react/components/accountView/userDetails/PrivacySettingsView";
import ChangePasswordView from "react/components/accountView/userDetails/ChangePasswordView";
import AddNewCard from "react/components/accountView/userDetails/AddNewCard";
import ProblemView from "react/components/problem/ProblemView";
import SubscriptionCancelView from "react/components/parkingViews/Subscriptions/SubscriptionCancelView";
import { FutureBookingView } from "react/components/future-booking/future-booking.view";
import { HowItWorks_UpcomingWeeksView } from "react/components/future-booking/how-it-works/upcoming-weeks.view";
import { HowItWorks_ThisWeekView } from "react/components/future-booking/how-it-works/this-week.view";
import SubscriptionDetailsView from "react/components/subscriptions/SubscriptionDetailsView";
import PreferredBaysSelectionView from "react/components/preferredBays/PreferredBaysSelectionView";
import VerifyEmailView from "react/components/landing/VerifyEmailView";
import SingleSubscriptionView from "react/components/parkingViews/Subscriptions/SingleSubscriptionView";
import StartChargingByLinkView from "react/components/parkDetails/StartChargingByLinkView";
import SubscriptionInvoicesView from "react/components/subscriptions/subscriptionListItems/SubscriptionInvoicesView";
import { ConfirmedBookingView } from "react/components/future-booking/confirmedBooking/confirmedBookingView";
import { SubscriptionSharingCreditView } from "react/components/subscriptions/SubscriptionSharingCreditView";
import ChatDetailsView from "react/components/tandemChat/ChatDetailsView";
import CampusScreenView from "react/components/parkDetails/CampusScreenView";
import ConnectEVNozzle from "react/components/ev/ConnectEVNozzle";
import TandemChatView from "react/components/tandemChat/TandemChatView";
import ConfirmStartTandemParkingView from "react/components/tandemParking/ConfirmStartTandemParkingView";
import MagicLinkView from "react/components/landing/MagicLinkView";
import { MyRewardsView } from "react/components/rewards/myRewardsView";
import RetryPayment from "react/components/parkingViews/RetryPayment";
import QrScannerRoot from "react/components/common/qrscanner/QrScannerRoot";
import ScanSensorQrCodeRoot from "react/components/admin/ScanSensorQrCodeRoot";
import AssignSensorQrCodeRoot from "react/components/admin/AssignSensorQrCodeRoot";
import { HowItWorks_Rewards } from "react/components/rewards/how-it-works.view";
import AssignSensorBayList from "react/components/admin/AssignSensorBayList";
import AssignQRForBay from "react/components/admin/AssignQRForBay";
import AdminLoginAsUserView from "react/components/admin/AdminLoginAsUserView";
import WarningOpenSessionView from "react/components/parkingViews/WarningOpenSessionView";
import AutoEndSessionView from "react/components/parkingViews/AutoEndSessionView";
import UpdateCheckView from "react/components/landing/UpdateCheckView";
import TeamsLandingPage from "react/components/teams/TeamsLandingPage";
import TeamsAuthStart from "react/components/teams/TeamsAuthStart";
import TeamsAuthEnd from "react/components/teams/TeamsAuthEnd";
import SharingPoolView from "react/components/parkDetails/sharing-pool/SharingPoolView";
import EditVehicleView from "react/components/accountView/userDetails/EditVehicleView";
import AddNewCaseView from "react/components/support/AddNewCaseView";
import SelectBayView from "react/components/common/SelectBayView";

export type RouteComponents = {
    [Property in keyof RootStackParamList]: React.ComponentType<any>;
};

const routeComponents: RouteComponents = {
    [Routes.LandingView]: LandingView,
    [Routes.ParkDetailView]: ParkDetailView,
    [Routes.CampusScreenView]: CampusScreenView,
    [Routes.ConnectEVNozzle]: ConnectEVNozzle,
    [Routes.LoginOrSignUpView]: LoginOrSignupView,
    [Routes.SubscriptionDetailsView]: SubscriptionDetailsView,
    [Routes.VerifyEmailView]: VerifyEmailView,
    [Routes.MagicLinkView]: MagicLinkView,
    [Routes.ParkableMapView]: ParkableMapViewRoot,
    [Routes.ChangeServerView]: ChangeServerView,
    [Routes.UpdateCheckView]: UpdateCheckView,
    [Routes.FindParkView]: FindParkView,
    [Routes.SupportView]: SupportView,
    [Routes.AddNewCaseView]: AddNewCaseView,
    [Routes.CaseDetailsView]: CaseDetailsView,
    [Routes.MyDetailsView]: MyDetailsView,
    [Routes.CreateAccountView]: CreateAccountView,
    [Routes.ConfirmedBookingView]: ConfirmedBookingView,
    [Routes.StartSubscriptionSummaryView]: StartSubscriptionSummaryView,
    [Routes.ConfirmStartParkingView]: ConfirmStartParkingView,
    [Routes.PreferredBaysView]: PreferredBaysView,
    [Routes.PreferredBaysSelectionView]: PreferredBaysSelectionView,
    [Routes.SelectParkView]: SelectParkView,
    [Routes.PricingView]: PricingView,
    [Routes.ActiveSession]: ActiveSessionView,
    [Routes.ChangePasswordView]: ChangePasswordView,
    [Routes.ForgotPasswordView]: ForgotPasswordView,
    [Routes.AccountView]: AccountView,
    [Routes.SamlLoginView]: SamlLoginView,
    [Routes.SingleSubscriptionView]: SingleSubscriptionView,
    [Routes.MapSettingsView]: MapSettingsView,
    [Routes.SubscriptionCancelView]: SubscriptionCancelView,
    [Routes.AcceptTsAndCsView]: AcceptTsAndCsView,
    [Routes.SessionHistoryView]: SessionHistoryView,
    [Routes.UserBookingsAndRequestsView]: BookingsAndRequestsView,
    [Routes.SubscriptionListView]: ListSubscriptionView,
    [Routes.SubscriptionInvoicesView]: SubscriptionInvoicesView,
    [Routes.SubscriptionSharingCreditView]: SubscriptionSharingCreditView,
    [Routes.ConfirmStartTandemParkingView]: ConfirmStartTandemParkingView,
    [Routes.AddNewVehicleView]: AddNewVehicleView,
    [Routes.EditVehicleView]: EditVehicleView,
    [Routes.VehiclesView]: VehiclesView,
    [Routes.MessagesView]: MessagesView,
    [Routes.SelectSubscriptionPlanView]: SelectSubscriptionPlanView,
    [Routes.ParkTomorrowView]: ParkTomorrowView,
    [Routes.ConfirmStartReservationView]: ConfirmStartReservationView,
    [Routes.VouchersView]: VouchersView,
    [Routes.ChatDetailsView]: ChatDetailsView,
    [Routes.TandemChatView]: TandemChatView,
    [Routes.SessionSummary]: SessionSummary,
    [Routes.FutureBookingView]: FutureBookingView,
    [Routes.Problem]: ProblemView,
    [Routes.QrScannerView]: QrScannerRoot,
    [Routes.HowItWorks_ThisWeekView]: HowItWorks_ThisWeekView,
    [Routes.HowItWorks_UpcomingWeeksView]: HowItWorks_UpcomingWeeksView,
    [Routes.NotificationSettingsView]: NotificationSettingsView,
    [Routes.RfidCardsView]: RfidCardsView,
    [Routes.AddEditRfidCardView]: AddEditRfidCardView,
    [Routes.AssignQRForBay]: AssignQRForBay,
    [Routes.AssignSensorBayList]: AssignSensorBayList,
    [Routes.AssignSensorQrCodeView]: AssignSensorQrCodeRoot,
    [Routes.ScanSensorQrCodeView]: ScanSensorQrCodeRoot,
    [Routes.CreditCardView]: CreditCards,
    [Routes.AddNewCard]: AddNewCard,
    [Routes.RetryPaymentRequest]: RetryPayment,
    [Routes.SelectBayView]: SelectBayView,
    [Routes.ParkAvailabilityView]: ParkAvailabilityView,
    [Routes.SharingPoolView]: SharingPoolView,
    [Routes.VoucherDetail]: VoucherDetail,
    [Routes.AddVoucherCode]: AddVoucherCode,
    [Routes.StartChargingByLinkView]: StartChargingByLinkView,
    [Routes.PrivacySettingsView]: PrivacySettingsView,
    [Routes.SessionHistoryDetailView]: SessionHistoryDetailView,
    [Routes.WarningOpenSessionView]: WarningOpenSessionView,
    [Routes.AutoEndSessionView]: AutoEndSessionView,
    [Routes.MyRewardsView]: MyRewardsView,
    [Routes.HowItWorks_Rewards]: HowItWorks_Rewards,
    [Routes.AdminLoginAsUserView]: AdminLoginAsUserView,
    [Routes.TeamsLandingPage]: TeamsLandingPage,
    [Routes.TeamsAuthStart]: TeamsAuthStart,
    [Routes.TeamsAuthEnd]: TeamsAuthEnd,
};

export default routeComponents;
