import { Nully } from "../constants/nully";
import { useAppDispatch, useReduxMaps, useSelector } from "../redux/redux";
import { IRootReducer } from "../redux/reducers/main";
import { usePushNotifications } from "./pushNotifications/pushNotification";
import { useEffect, useMemo, useState } from "react";
import { useCurrentParkingSession } from "../api/parking";
import { useEmployeeSubscriptions } from "../api/employeeSubscription/employeeSubscription.api";
import { Routes } from "./root/root.paths";
import * as Linking from "expo-linking";
import { FirebaseService } from "../services/firebase.service";
import { getQRAction } from "../redux/actions/qrAction";
import QRAction, { ActionType } from "../model/QRAction";
import QRActionHandler from "../components/common/qrscanner/QRActionHandler";
import { getInitialUrl, Route, useNavigation } from "./constants";
import { useTeamsContext } from "../components/teams/context";
import { EmployeeSubscriptionDTO, EmployeeSubscriptionStatus } from "../dto/EmployeeSubscriptionDTO";
import {Platform} from "react-native";

export const useInitialRoute = (): { initialRoute: Route; initialRouteLoading: boolean } => {
    const dispatch = useAppDispatch();
    const { user, createUserData } = useSelector((state: IRootReducer) => state.user);
    const navigation = useNavigation();
    const pushRoute = usePushNotifications();
    const [deepLinkRoute, setDeepLinkRoute] = useState<Route>();
    const {
        parkSession,
        isLoading: _loadingSession,
        mutate: mutateSession,
        isValidating: validatingSession,
    } = useCurrentParkingSession();
    const {
        data,
        isLoading: _loadingSubscriptions,
        mutate: mutateSubscriptions,
        isValidating: validatingSubscriptions,
    } = useEmployeeSubscriptions();
    const { preferences } = useReduxMaps();

    const loadingSession = _loadingSession || validatingSession;
    const loadingSubscriptions = _loadingSubscriptions || validatingSubscriptions;

    const landingRoute = useMemo(() => ({ name: Routes.LandingView }), []);
    const subscriptionListRoute = useMemo(() => ({ name: Routes.SubscriptionListView }), []);
    const activeSessionRoute = useMemo(() => ({ name: Routes.ActiveSession }), []);

    const { insideTeamsEnvironment, teamsLoggingIn } = useTeamsContext();

    const [initialRoute, setInitialRoute] = useState<Route>({ name: Routes.ParkableMapView });
    const [initialRouteLoading, setInitialRouteLoading] = useState(true);

    const hasActiveSubscription = data?.subscriptions.some((r: EmployeeSubscriptionDTO) =>
        [
            EmployeeSubscriptionStatus.Active,
            EmployeeSubscriptionStatus.HostEnding,
            EmployeeSubscriptionStatus.LeaserEnding,
            EmployeeSubscriptionStatus.PastDue,
        ].includes(r.status)
    );

    // Don't show ActiveSessionView on app load if it's a reservation.
    const hasActiveSession = parkSession?.startedAt;

    async function handleInitialUrl() {
        const initialURL = await getInitialUrl();
        if (initialURL) {
            await handleDeeplink(initialURL);
        }
    }

    useEffect(() => {
        handleInitialUrl().catch(console.error);
    }, []);

    useEffect(() => {
        if (!insideTeamsEnvironment) {
            const subscription = Linking.addEventListener("url", listener);
            return () => subscription?.remove();
        }
    }, [insideTeamsEnvironment]);

    const listener = async (event: Linking.EventType) => {
        await handleDeeplink(event.url);
    };

    const handleDeeplink = async (deeplink: string) => {
        if (Platform.OS === "web" || !deeplink) {
            return;
        }

        if (FirebaseService.isSignInWithEmailLink(deeplink)) {
            // This is handled in AuthProvider.tsx
            return;
        }

        //non-QRAction links
        if (deeplink.includes("future_booking")) {
            setDeepLinkRoute({ name: Routes.UserBookingsAndRequestsView });
            return;
        }

        if (deeplink.includes("teams")) {
            console.log(`deepLink ${deeplink}`);
            return;
        }

        const regex = /\/\w+$/gi;
        const linkId = (deeplink.match(regex) ?? [null])[0]?.replace("/", "");
        if (linkId) {
            dispatch(getQRAction(linkId, linkHandler));
        }
    };

    const linkHandler = (qrAction: QRAction) => {
        console.log("main Handling QR Action", qrAction, qrAction.actionType);
        const { parkId, bayId, campusId, organisationId } = qrAction.actionParams;
        switch (qrAction.actionType) {
            case ActionType.ViewPark: {
                if (!!parkId) {
                    setDeepLinkRoute({
                        name: Routes.ParkDetailView,
                        params: { parkId },
                    });
                }
                break;
            }

            case ActionType.ViewCampus: {
                if (!!campusId) {
                    setDeepLinkRoute({
                        name: Routes.CampusScreenView,
                        params: { campusId, organisationId, ParkingType: preferences.parkingType },
                    });
                }
                break;
            }

            case ActionType.StartCharging: {
                if (!!parkId && !!bayId) {
                    console.log("qr action is StartCharging", { parkId, bayId });
                    setDeepLinkRoute({
                        name: Routes.StartChargingByLinkView,
                        params: { parkId, bayId },
                    });
                }
                break;
            }
            default:
                break;
        }

        QRActionHandler(qrAction, navigation, preferences);
    };

    const createAccountRoute = useMemo(
        () => ({
            name: Routes.CreateAccountView,
            params: {
                email: createUserData?.email,
                firstName: createUserData?.firstName,
                lastName: createUserData?.lastName,
                usingSSO: true,
                logoutOnBack: true,
            },
        }),
        [createUserData]
    );

    const getInitialRoute = (): Route => {
        if (!user && !createUserData) {
            if (insideTeamsEnvironment && teamsLoggingIn) {
                return { name: Routes.TeamsLandingPage };
            }
            return landingRoute;
        }
        if (createUserData) {
            console.log("returning createAccount route");
            return createAccountRoute;
        }
        if (pushRoute) {
            console.log("returning push route");
            return pushRoute;
        }
        if (deepLinkRoute) {
            return deepLinkRoute;
        }
        if (hasActiveSession) {
            console.log("returning active session");
            return activeSessionRoute;
        }
        if (hasActiveSubscription) {
            console.log("returning subscriptions");
            return subscriptionListRoute;
        }
        return { name: Routes.ParkableMapView };
    };

    useEffect(() => {
        if (loadingSession || loadingSubscriptions) {
            return;
        }
        setInitialRoute(getInitialRoute());
        setInitialRouteLoading(false);
    }, [loadingSession, loadingSubscriptions, createUserData]);

    useEffect(() => {
        if (!user && !createUserData) {
            return;
        }
        setInitialRouteLoading(true);
        mutateSession();
        mutateSubscriptions();
    }, [user?.email, createUserData]);

    return {
        initialRoute,
        initialRouteLoading,
    };
};
